import createEmotionCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { NextThemeProvider } from '@tamagui/next-theme'
import tamaguiConfig from '@wordup/assets/configs/tamagui.config'
import { Provider as JotaiProvider } from 'jotai'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { IntercomProvider } from 'react-use-intercom'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { TamaguiProvider } from 'tamagui'
import AppSeo from '@/components/seo'
// import { GDPRModal } from '@/components/modal/variant/GDPR'
import { InitGoogleAdsense } from '@/features/ads/init-google-adsense'
import { PreviousRouteProvider } from '@/hooks/router/use-previous-route'
import { muiTheme } from '@/styles/mui-theme'

import '@/styles/globals.css'
import { useLayoutEffect } from 'react'

const CommonLayout = dynamic(() => import('@/layouts/common'), { ssr: false })

const WuToastContainer = dynamic(() => import('@/components/toast/toast-container'), { ssr: false })

const cache = createEmotionCache({ key: 'css', prepend: true })

function App({ Component, pageProps }: AppProps) {
  useLayoutEffect(() => {
    const _handleToggleDarkMode = () => {
      const isDarkMode = localStorage.getItem('isDarkMode') === 'true'

      if (isDarkMode) {
        import('darkreader').then(DarkReader => {
          DarkReader.enable({
            brightness: 90,
            contrast: 100,
            sepia: 0,
          })
        })
      }
    }

    _handleToggleDarkMode()
  }, [])

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
        />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge,chrome=1' />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='format-detection' content='telephone=no' />
        <meta name='theme-color' content='#383b3d' />
        <meta
          name='google-site-verification'
          content='hzwyMaoSMpI-w07ToMQJvQCiQkVMcQHwr05_aKTNQWQ'
        />

        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='https://public.wordup.com.tw/favicon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='https://public.wordup.com.tw/favicon.png'
        />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Head>

      <InitGoogleAdsense />

      <AppSeo />

      {/* <GDPRModal /> */}

      <CacheProvider value={cache}>
        <IntercomProvider
          appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID as string}
          initializeDelay={5000}
        >
          <JotaiProvider>
            <StyledThemeProvider theme={muiTheme}>
              <MuiThemeProvider theme={muiTheme}>
                <TamaguiWrapper>
                  <CssBaseline />
                  <WuToastContainer />

                  <CommonLayout>
                    <PreviousRouteProvider>
                      <Component {...pageProps} />{' '}
                    </PreviousRouteProvider>
                  </CommonLayout>
                </TamaguiWrapper>
              </MuiThemeProvider>
            </StyledThemeProvider>
          </JotaiProvider>
        </IntercomProvider>
      </CacheProvider>
    </>
  )
}

export default appWithTranslation(App)

const TamaguiWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <TamaguiProvider config={tamaguiConfig} defaultTheme={'light'}>
      <NextThemeProvider>{children}</NextThemeProvider>
    </TamaguiProvider>
  )
}
